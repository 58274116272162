import { Button, Input, message, Spin } from 'antd';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import Pill from '../components/Pill';
import { useQuery } from '@apollo/client';
import { GET_KIT } from '../queries';

const Login = ({ kit }) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const { validateKitPassword } = useContext(AppContext);

  const submit = () => {
    if (
      validateKitPassword({
        kit,
        password,
      })
    ) {
      navigate(`/kit/${kit.id}`);
    } else {
      message.error('Nope');
    }
  };

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '260px',
        margin: '100px auto',
      }}
    >
      {kit && <Pill title={kit.name} img={kit.cover.url} />}
      <p>Enter the passcode you received with your kit:</p>
      <Input.Group compact>
        <Input
          onKeyDown={e => {
            if (e.key === 'Enter') {
              submit();
            }
          }}
          style={{ width: 'calc(100% - 100px)' }}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button onClick={submit}>Submit</Button>
      </Input.Group>
    </div>
  );
};

const PreLogin = () => {
  const { kitId } = useParams();

  const { loading, data } = useQuery(GET_KIT, {
    variables: {
      id: kitId,
    },
  });

  if (loading || !data) return <Spin />;

  return <Login kit={data.kit} />;
};

export default PreLogin;
