import styled from 'styled-components';

const Wrapper = styled.div`
  background-image: url(${props => props.imageUrl});
  background-color: #222;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  margin: 0px 0px 20px 0px;
  position: relative;
  overflow: hidden;
  height: 340px;
  width: 100%;
`;

const Title = styled.div`
  color: white;
  text-decoration: none;
  position: absolute;
  font-size: 28px;
  text-shadow: 0px 0px 5px black;
  background: linear-gradient(to top, black, transparent);
  text-align: left;
  padding: 20px 20px;
  bottom: 0px;
  width: 100%;
`;

const Pill = ({ title, sub, img }) => {
  return (
    <Wrapper imageUrl={img}>
      <Title className="cal">{title}</Title>
    </Wrapper>
  );
};

export default Pill;
