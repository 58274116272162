import React from 'react';
import utilities from '../utilities';

export const AppContext = React.createContext({});

const AppProvider = ({ children }) => {
  const getKitKey = ({ kitId }) => `kit-${kitId}`;

  return (
    <AppContext.Provider
      value={{
        isLoggedIntoKit: ({ kit }) => {
          const storedPwd = utilities.retrieve({ key: getKitKey({ kitId: kit.id }) });

          // Nothing stored
          if (!storedPwd) return false;

          // Wrong pwd
          if (storedPwd !== kit.code) return false;

          return true;
        },
        validateKitPassword: ({ kit, password }) => {
          if (utilities.encrypt(kit.code) !== utilities.encrypt(password)) {
            return false;
          }

          utilities.store({ key: getKitKey({ kitId: kit.id }), record: password });

          return true;
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
