import { Avatar, Button, List, Modal, Spin } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { AppContext } from '../context/AppContext';
import VideoJS from '../player/VideoJS';
import Colors from '../Colors';
import { Icon } from '@iconify/react';
import util from '../utilities';
import { useQuery } from '@apollo/client';
import { GET_KIT } from '../queries';

const Wrapper = styled.div`
  padding: 40px 20px;
  width: 100%;
`;
const Title = styled.div`
  font-size: 30px;
  text-align: center;
`;

const Section = styled.div`
  margin: 20px 0px 40px 0px;
`;

const SectionTitle = styled.div`
  color: #555;
  font-size: 20px;
  padding-left: 10px;
  margin-bottom: 15px;
`;

const OverlaySection = styled.div`
  color: ${Colors.gray5};
  font-size: 14px;
  line-height: 10px;
`;

const OverlayVideo = styled.div`
  color: white;
  font-size: 26px;
  line-height: 30px;
`;

const SegmentsWrapper = styled.div`
  background: transparent;
  overflow: hidden;
  display: flex;
  position: absolute;
  margin: 20px 24px;
  z-index: 100;
  top: 0px;
  width: calc(100% - 48px);
`;
const Segment = styled.div`
  border-radius: 2px;
  height: 0px;
  flex: 1;
  margin: 5px 3px;
  position: relative;
  padding: 2px 5px;
  background-color: rgba(255, 255, 255, 0.35);

  &::after {
    border-radius: 2px;
    content: '';
    height: 100%;
    width: ${props => props.percent}%;
    transition: all 0.2s ease-in-out;
    background-color: white;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
  }
`;

const Footer = styled.div`
  display: flex;
  padding: 12px;
  background: black;
  align-items: center;
`;

const CloseButton = styled.div`
  color: rgba(255, 255, 255, 0.35);
  font-size: 42px;
  position: absolute;
  right: 0px;
  top: 20px;
  &:hover {
    color: white;
  }
`;

const Segments = ({ section, player, currentVideoIndex }) => {
  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    player.on('timeupdate', () => {
      const percent = (player.currentTime() / player.duration()) * 100;
      setProgressPercent(percent);
    });
  }, [player]);

  return (
    <SegmentsWrapper>
      {section.steps.map((v, i) => {
        let percent = 0;
        if (i < currentVideoIndex) {
          percent = 100;
        } else if (i === currentVideoIndex) {
          percent = progressPercent;
        }

        return <Segment key={i} percent={percent} />;
      })}
    </SegmentsWrapper>
  );
};

const VideoPlayer = ({ section, video, currentVideoIndex }) => {
  const playerRef = useRef(null);
  const [playerReady, setPlayerReady] = useState(false);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: video.src,
        type: 'video/mp4',
      },
    ],
  };

  return (
    <div style={{ position: 'relative' }}>
      {playerReady && (
        <Segments section={section} video={video} player={playerRef.current} currentVideoIndex={currentVideoIndex} />
      )}
      <VideoJS
        options={videoJsOptions}
        onReady={player => {
          playerRef.current = player;

          setPlayerReady(true);

          // You can handle player events here, for example:
          player.on('waiting', () => {
            console.log('player is waiting');
          });

          player.on('dispose', () => {
            console.log('player will dispose');
          });
        }}
      />
    </div>
  );
};

const Kit = ({ kit }) => {
  const navigate = useNavigate();

  const { isLoggedIntoKit } = useContext(AppContext);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    if (!isLoggedIntoKit({ kit })) {
      navigate(`/kit/login/${kit.id}`);
      return;
    }
  }, [isLoggedIntoKit, kit, navigate]);

  const selectedSection = kit.sections?.find(s => {
    return s?.steps.some(v => v.id === selectedVideo?.id);
  });

  const currentSectionIndex = kit.sections?.findIndex(s => s.id === selectedSection?.id);
  const currentVideoIndex = selectedSection?.steps.findIndex(v => v.id === selectedVideo?.id);

  console.log(kit);

  return (
    <Wrapper>
      <Title className="cal">{kit.name}</Title>
      <Modal
        className="video-player-modal"
        open={selectedVideo}
        destroyOnClose
        style={{ maxWidth: window.innerWidth, top: util.isMobile() ? 0 : 20, width: '100%' }}
        bodyStyle={{
          background: 'black',
        }}
        maskStyle={{
          background: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(3px)',
        }}
        closeIcon={
          <CloseButton>
            <Icon icon="material-symbols:close" />
          </CloseButton>
        }
        maskTransitionName=""
        footer={
          <Footer>
            <Button
              icon={<ArrowLeftOutlined />}
              size="large"
              ghost
              onClick={() => {
                // First video
                if (currentVideoIndex === 0) {
                  // Check if this is the first section
                  if (currentSectionIndex === 0) {
                    // Nothing to do here
                  } else {
                    const previousSection = kit.sections[currentSectionIndex - 1];
                    setSelectedVideo(previousSection.steps[0]);
                  }
                } else {
                  setSelectedVideo(selectedSection.steps[currentVideoIndex - 1]);
                }
              }}
            />
            <div style={{ flex: 1, textAlign: 'center' }}>
              <OverlaySection>{selectedSection?.name}</OverlaySection>
              <OverlayVideo className="cal">{selectedVideo?.name}</OverlayVideo>
            </div>
            <Button
              icon={<ArrowRightOutlined />}
              size="large"
              ghost
              onClick={() => {
                // Last video
                if (currentVideoIndex === selectedSection.steps.length - 1) {
                  // Check if this is the last section
                  if (currentSectionIndex === kit.sections?.length - 1) {
                    // TODO Congrats!
                  } else {
                    const nextSection = kit.sections[currentSectionIndex + 1];
                    setSelectedVideo(nextSection.steps[0]);
                  }
                } else {
                  setSelectedVideo(selectedSection.steps[currentVideoIndex + 1]);
                }
              }}
            />
          </Footer>
        }
        onCancel={() => {
          setSelectedVideo(null);
        }}
      >
        <VideoPlayer section={selectedSection} video={selectedVideo} currentVideoIndex={currentVideoIndex} />
      </Modal>
      {kit.sections.map((s, i) => (
        <Section key={i}>
          <SectionTitle className="cal">{s.name}</SectionTitle>
          <List
            style={{ background: 'white' }}
            bordered
            itemLayout="horizontal"
            dataSource={s.steps}
            renderItem={(video, i) => (
              <List.Item
                style={{ justifyContent: 'left', cursor: 'pointer' }}
                onClick={() => {
                  setSelectedVideo(video);
                }}
              >
                <Avatar shape="square" style={{ marginRight: 20 }} size={40} />
                <div>{video.name}</div>
              </List.Item>
            )}
          />
        </Section>
      ))}
    </Wrapper>
  );
};

const PreKit = () => {
  const { kitId } = useParams();

  const { loading, data } = useQuery(GET_KIT, {
    variables: {
      id: kitId,
    },
  });

  if (loading || !data) return <Spin />;

  return <Kit kit={data.kit} />;
};

export default PreKit;
