import { gql } from '@apollo/client';

export const GET_KITS = gql`
  query getKits {
    kits {
      id
      name
      cover {
        id
        url
      }
    }
  }
`;

export const GET_KIT = gql`
  query getKit($id: ID!) {
    kit(where: { id: $id }) {
      id
      name
      code
      cover {
        id
        url
      }
      sections {
        id
        name
        steps {
          id
          name
        }
      }
    }
  }
`;
