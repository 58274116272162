import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Pill from '../components/Pill';
import { Col, Row, Spin } from 'antd';
import { GET_KITS } from '../queries';
import { useQuery } from '@apollo/client';

const Container = styled.div`
  text-align: center;
  padding: 0px 20px;

  p {
    font-size: 20px;
  }
`;

const Home = () => {
  const { loading, data } = useQuery(GET_KITS);

  return (
    <Container>
      <p>Welcome! Please select your kit:</p>
      {loading && <Spin />}
      {!loading && data && (
        <Row gutter={20}>
          {data.kits.map((kit, i) => {
            return (
              <Col key={i} xs={24} sm={12} md={8}>
                <Link to={`/kit/${kit.id}`}>
                  <Pill title={kit.name} img={kit.cover?.url} />
                </Link>
              </Col>
            );
          })}
        </Row>
      )}
      <p>
        <a href="https://ploshy.com">I need a kit!</a>
      </p>
    </Container>
  );
};

export default Home;
