import { ShoppingCartOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Colors from '../Colors';

const Wrapper = styled.div`
  background: ${Colors.secondary};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  position: sticky;
  padding: 15px;
  width: 100%;
`;

const Logo = styled.div`
  font-size: 20px;
  text-decoration: none;
  color: black;
  padding: 0px 0px;
  float: left;
`;

const Nav = () => {
  return (
    <Wrapper>
      <Link to="/">
        <Logo className="cal">ploshy</Logo>
      </Link>
      <div style={{ flex: 1 }} />
      <a href="https://ploshy.com">
        <Button icon={<ShoppingCartOutlined />}>Store</Button>
      </a>
    </Wrapper>
  );
};

export default Nav;
