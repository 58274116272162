import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import reportWebVitals from './reportWebVitals';
import './index.css';
import AppProvider from './context/AppContext';

import Home from './routes/Home';
import Kit from './routes/Kit';
import Login from './routes/Login';
import Nav from './components/Nav';
import { Layout, QRCode } from 'antd';

const client = new ApolloClient({
  uri: 'https://us-east-1-shared-usea1-02.cdn.hygraph.com/content/cldf5mpa220fi01ug29cobu5r/master',
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AppProvider>
        <BrowserRouter>
          <Layout
            style={{
              background: 'transparent',
            }}
          >
            <Nav />
            <Layout.Content
              style={{
                width: '100%',
                maxWidth: 768,
                height: '100%',
                background: 'transparent',
                margin: 'auto',
              }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/kit/:kitId" element={<Kit />} />
                <Route path="/kit/login/:kitId" element={<Login />} />
                <Route
                  path="/qr"
                  element={
                    <QRCode
                      style={{ marginTop: 100 }}
                      value="https://app.ploshy.com"
                      icon={`${process.env.PUBLIC_URL}/logo.png`}
                      size={512}
                      iconSize={128}
                    />
                  }
                />
              </Routes>
            </Layout.Content>
          </Layout>
        </BrowserRouter>
      </AppProvider>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
